@import 'erpcore/assets/scss/main';

.page-content {
    $this: &;
    position: relative;
    width: 100%;
    display: block;
    padding: $spacing * 4 0;

    &__section {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: $spacing * 4;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        border: none;
        padding: 0;
        margin: 0;
    }

    &__columns {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing * 2;

        &:last-child {
            margin-bottom: 0;
        }

        #{$this}__column {
            flex-basis: 50%;
            width: 50%;
            margin-right: $spacing * 2;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
    }

    &__title {
        display: inline-block;
        width: 100%;
        font: $font-2;
        margin: ($spacing * 2) 0px ($spacing * 3) 0px;

        &:first-child {
            margin-top: 0px;
        }
    }

    &--dashboard {
        @include breakpoint($to: mobile) {
            .page-content__columns {
                flex-direction: column;
            }

            .page-content__column {
                width: 100%;
                flex-basis: 100%;
                margin-right: 0;
                margin-bottom: $spacing * 2;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__helper-list {
        margin-bottom: $spacing * 3;
    }
}
