@import 'erpcore/assets/scss/main';

.teamLeader-wrapper{
    display: flex;

    @include breakpoint(small){
        flex-direction: column;
    }

    &__image{
        min-width: 150px;

        @include breakpoint(small){
            min-width: auto;
            margin: 0 auto $spacing*2;
        }
    }

    &__body{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        &-heading{
            font: $font-timer;
        }

        &-content{
            color: getColor('grey-4');

            &-team{
                font-weight: 400;
                color: getColor('main-grey');
            }
        }
    }
}