$colors: (
    error: var(--errorColor),
    primary-original: var(--primaryColorOriginal),
    primary-hover-original: var(--primaryHoverOriginal),
    primary: var(--primaryColor),
    primary-hover: var(--primaryHover),
    admin: #384679,
    admin-hover: #2b365e,
    black: #101010,
    dark-grey: #1e1e1e,
    dark-grey-hover: rgba(#323232, 0.8),
    red: #e51c23,
    red-hover: #c11303,
    light-blue: #d9e3ef,
    light-blue-hover: #c5ced9,
    main-grey: #191919,
    mid-grey: #646464,
    grey-1: #f7f7f7,
    grey-2: #e2e2e2,
    grey-3: #ababab,
    grey-4: #6b6b6b,
    grey-5: #bbbcbf,
    silver-grey: #b3b3b3,
    silver-light-grey: #dedede,
    light-grey: #f2f2f2,
    white: #ffffff,
    sun: #ffdd29,
    tomato: #ed767a,
    sky: #35a2df,
    apple: #2aa87b,
    lavander: #bf87e1,
    neon: #7474ee,
    orange: #f88d4c
);
