@import 'erpcore/assets/scss/main';

.notifications-log {
    &__modal {
        min-width: 609px;

        @include breakpoint(mobile) {
            min-width: auto;
            position: absolute;
            border-radius: 0;
            box-shadow: none;
            top: 70px;
            bottom: 0;
            margin: 0;
            max-width: 100%;
        }

        .modal__header {
            padding: $spacing * 2 $spacing * 2.5 0;
        }

        .modal__content {
            max-height: 300px;
            overflow: auto;
            margin: $spacing * 4 $spacing * 3 $spacing * 4 0;
            padding: 0 24px;

            @include breakpoint(mobile) {
                max-height: calc(var(--appHeight) - 186px);
                margin-bottom: $spacing * 5;
            }

            &::-webkit-scrollbar {
                width: 5px;
                cursor: pointer;
            }
            &::-webkit-scrollbar-track {
                background: getColor(grey-1);
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
                background: getColor(grey-2);
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: getColor(grey-3);
            }
        }

        .modal__close {
            height: $spacing * 3;
            width: $spacing * 3;

            @include breakpoint($from: mobile) {
                top: 18px;
            }

            .svg {
                height: 12px;
                width: 12px;
            }
        }

        .modal__title {
            font: $font-timer;
        }

        .notification {
            &__title {
                font: $font-content;
                color: getColor(grey-4);
            }
            .svg {
                svg {
                    width: 17px;
                    height: 17px;
                }
            }

            &__time {
                font: $font-notification;
                color: getColor(grey-5);
            }
        }
    }
}

.participant-custom-title {
    .notification {
        &__title {
            font: $font-content;
            color: getColor(admin);
        }

        &__text {
            font: $font-notification;
            color: getColor(grey-4);
        }
    }
}
