.appVersion {
    align-self: flex-end;
    margin: auto auto 10px;
    font-size: 11px;
    color: #ababab;
}

@media (min-width: 1031px) {
    .appVersionVisual {
        display: block;
        position: fixed;
        bottom: 4px;
        right: 4px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #e93b3b;
        opacity: 0.8;
        pointer-events: none;
    }
}
