@font-face {
    font-family: 'SofiaPro';
    src: url(~erpcore/assets/fonts/SofiaPro/SofiaProLight.eot);
    src: url(~erpcore/assets/fonts/SofiaPro/SofiaProLight.woff2) format('woff2'),
        url(~erpcore/assets/fonts/SofiaPro/SofiaProLight.woff) format('woff'),
        url(~erpcore/assets/fonts/SofiaPro/SofiaProLight.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SofiaPro';
    src: url(~erpcore/assets/fonts/SofiaPro/SofiaProRegular.eot);
    src: url(~erpcore/assets/fonts/SofiaPro/SofiaProRegular.woff2) format('woff2'),
        url(~erpcore/assets/fonts/SofiaPro/SofiaProRegular.woff) format('woff'),
        url(~erpcore/assets/fonts/SofiaPro/SofiaProRegular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SofiaPro';
    src: url(~erpcore/assets/fonts/SofiaPro/SofiaProMedium.eot);
    src: url(~erpcore/assets/fonts/SofiaPro/SofiaProMedium.woff2) format('woff2'),
        url(~erpcore/assets/fonts/SofiaPro/SofiaProMedium.woff) format('woff'),
        url(~erpcore/assets/fonts/SofiaPro/SofiaProMedium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SofiaPro';
    src: url(~erpcore/assets/fonts/SofiaPro/SofiaProSemiBold.eot);
    src: url(~erpcore/assets/fonts/SofiaPro/SofiaProSemiBold.woff2) format('woff2'),
        url(~erpcore/assets/fonts/SofiaPro/SofiaProSemiBold.woff) format('woff'),
        url(~erpcore/assets/fonts/SofiaPro/SofiaProSemiBold.ttf) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'SofiaPro';
    src: url(~erpcore/assets/fonts/SofiaPro/SofiaProBold.eot);
    src: url(~erpcore/assets/fonts/SofiaPro/SofiaProBold.woff2) format('woff2'),
        url(~erpcore/assets/fonts/SofiaPro/SofiaProBold.woff) format('woff'),
        url(~erpcore/assets/fonts/SofiaPro/SofiaProBold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url(~erpcore/assets/fonts/Rubik/Rubik-Light.eot);
    src: url(~erpcore/assets/fonts/Rubik/Rubik-Light.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Rubik/Rubik-Light.woff) format('woff'),
        url(~erpcore/assets/fonts/Rubik/Rubik-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url(~erpcore/assets/fonts/Rubik/Rubik-Regular.eot);
    src: url(~erpcore/assets/fonts/Rubik/Rubik-Regular.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Rubik/Rubik-Regular.woff) format('woff'),
        url(~erpcore/assets/fonts/Rubik/Rubik-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url(~erpcore/assets/fonts/Rubik/Rubik-Medium.eot);
    src: url(~erpcore/assets/fonts/Rubik/Rubik-Medium.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Rubik/Rubik-Medium.woff) format('woff'),
        url(~erpcore/assets/fonts/Rubik/Rubik-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url(~erpcore/assets/fonts/Rubik/Rubik-Bold.eot);
    src: url(~erpcore/assets/fonts/Rubik/Rubik-Bold.woff2) format('woff2'),
        url(~erpcore/assets/fonts/Rubik/Rubik-Bold.woff) format('woff'),
        url(~erpcore/assets/fonts/Rubik/Rubik-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}
