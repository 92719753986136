@import 'erpcore/assets/scss/main';

.svg {
    display: inline-block;
    width: $spacing * 2;

    svg {
        display: block;
        width: 100%;
        height: auto;
    }

    @each $name, $color in $colors {
        &--#{$name} {
            fill: $color !important;

            svg {
                fill: $color !important;
            }

            path {
                fill: $color !important;
            }
        }
    }
}
