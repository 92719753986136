$font_headings: 'SofiaPro', sans-serif;
$font_text: 'Rubik', 'sans-serif';

$font-1: 700 28px/28px $font_headings;
$font-2: 700 18px/22px $font_headings;
$font-3: 700 16px/20px $font_headings;
$font-4: 700 14px/18px $font_headings;
$font-5: 700 12px/18px $font_headings;

$font-button: 500 14px/18px $font_text;
$font-button-small: 500 12px/14px $font_text;

$font-form: 300 16px/20px $font_text;
$font-filters: 300 14px/18px $font_text;
$font-body: 300 14px/18px $font_text;
$font-list: 300 12px/18px $font_text;
$font-label: 300 11px/16px $font_text;
$font-title-label: 400 11px/16px $font_text;
$font-footer: 400 12px/18px $font_text;

$font-caption: 700 10px/13px $font_headings;

$font-timer: 700 22px/26px $font_headings;

$font-content: 500 12px/14px $font_text;

$font-notification: 400 12px/18px $font-text;
