@import 'erpcore/assets/scss/main';

.ReactModal {
    $root: &;

    &__Overlay {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 10000;
        background-color: rgba(getColor(black), 0.4) !important;
        opacity: 0;
        transition: all 0.2s ease-in-out;
        overflow: auto;

        @include breakpoint($from: mobile) {
            body.ReactModal__Body--transparent-overlay-desktop & {
                background-color: transparent !important;
                pointer-events: none;
            }
        }

        @include breakpoint(mobile) {
            body.ReactModal__Body--transparent-overlay-mobile & {
                background-color: transparent !important;
                pointer-events: none;
            }
        }

        @include breakpoint(tablet) {
            padding: $spacing * 3;
        }

        @include breakpoint(mobile) {
            padding: $spacing * 2;
        }

        &--after-open {
            opacity: 1;

            #{$root}__Content {
                opacity: 1;
                transform: translateY(0px);
            }
        }

        &--before-close {
            opacity: 0;

            #{$root}__Content {
                opacity: 0;
                transform: translateY(-20px);
            }
        }
    }

    &__Content {
        position: relative;
        max-width: 570px;
        width: 100%;
        margin: $spacing * 5 auto;
        outline: none;
        border-radius: 5px;
        background: getColor(white);
        box-shadow: 4px 8px 16px rgba(51, 51, 51, 0.15);
        transition: all 0.2s ease-in-out;
        transform: translateY(-20px);
        opacity: 0;
        background: getColor(white);
        pointer-events: auto;
    }

    &__Body {
        &--open {
            overflow: hidden;
        }
    }
}

.modal {
    max-height: 95vh;
    overflow: auto;

    &__close {
        background: transparent;
        border: none;
        position: absolute;
        right: $spacing * 3;
        top: $spacing * 3;
        border-radius: 50%;
        padding: 0;
        height: $spacing * 4;
        width: $spacing * 4;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @include breakpoint(mobile) {
            top: $spacing * 2;
            right: $spacing * 2;
        }

        &:hover,
        &--active {
            background: getColor(gray);
        }

        .svg {
            margin: 0;
            width: 18px;
            height: 18px;
        }
    }

    &__content {
        padding: $spacing * 4;
        @include breakpoint(mobile) {
            padding: $spacing * 2;
        }

        &-scrollable {
        }
    }

    &__header {
        padding: $spacing * 4;
        padding-bottom: 0;
        @include breakpoint(mobile) {
            padding: $spacing * 2;
        }
    }

    &__title {
        margin: 0;
        font-size: 28px;

        @include breakpoint(mobile) {
            font-size: 22px;
        }

        svg {
            width: 20px;
            margin-right: 12px;
        }
    }

    &__subtitle {
        margin-top: $spacing * 2;
        font: $font-body;
        margin-bottom: 0px;
    }

    &--large {
        max-width: 1070px;
        overflow: auto;
    }

    &--medium {
        max-width: 800px;
    }

    &--small {
        max-width: 350px;
    }
}

.ReactModal__Body--open {
    &.ReactModal__Body--blur-desktop {
        #wrapper {
            @include breakpoint($from: mobile) {
                filter: blur(2px);
            }
        }
    }

    &.ReactModal__Body--blur-mobile {
        #wrapper {
            @include breakpoint(mobile) {
                filter: blur(2px);
            }
        }
    }
}
